.HeaderTabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  margin-left: 30px;
}

.headerStyle .ant-menu-horizontal {
  border-bottom: none !important;
}
.headerBorderBottom {
  border-bottom: 1px solid #f0f0f0;
}

/* underline css */

ul#menu {
  list-style: none;
}
ul#menu li {
  display: inline-block;
  padding: 0.3em 0.5em;
  position: relative;
  overflow: hidden;
}
ul#menu li::before {
  position: absolute;
  display: block;
  content: "";
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 5px;
  transition: 0.4s;
  background-color: #3399ff;
}
ul#menu li.active::before,
ul#menu li:hover::before {
  left: 0%;
}
ul#menu li a {
  text-decoration: none;
}

.underline {
  border-bottom-width: 2px solid blue;
}

